import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/virtual-dom",
  "date": "2016-03-31",
  "title": "VIRTUAL DOM",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you are using React, there is a better chance you have heard about virtual DOM. In a nutshell, virtual DOM is an abstract version of the DOM, a lightweight copy of the actual DOM which we can manipulate the way we want and then apply to the DOM tree."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This approach is faster than working with the actual DOM, because changing elements of the DOM tree affects the performance.`}</p>
    <script type="text/javascript" src="//www.google.com/trends/embed.js?hl=en-US&q=%22virtual+dom%22,+%22shadow+dom%22,+%22document+object+model%22&date=1/2010+57m&cmpt=q&tz=Etc/GMT-11&content=1&cid=TIMESERIES_GRAPH_AVERAGES_CHART&export=5&w=500&h=330"></script>
    <p>{`Before getting deep into Virtual DOM, let's investigate the problems with touching DOM directly.`}</p>
    <h2>{`Problems with DOM`}</h2>
    <p>{`One of the biggest issues with the DOM is it isn't designed for complex and dynamic web pages. The web has evolved to a degree, where having over thousands of nodes on one page has become quite the norm. Take Facebook and Twitter for example. The unlimited amounts of posts on a single page can bring in thousands of nodes easily. Manipulating these nodes, can takes seconds.`}</p>
    <p>{`Browsing on mobile also amplifies this problem. Mobiles phones have slower processors and the slowness of DOM manipulation can be well pronounced.`}</p>
    <p>{`On top of virtual DOM, you might have heard of shadow DOM as well. This can get confusing, so let's clear things up.`}</p>
    <h2>{`Standard DOM`}</h2>
    <p>{`The browser DOM or the W3C standard Document Object Model. When a web page is loaded, the web browser creates a DOM of the page which is constructed as a tree of Objects.`}</p>
    <Message type="info" content="The Document Object Model (DOM) is a programming interface for HTML and XML documents. It provides a structured representation of the document and it defines a way that the structure can be accessed from programs so that they can change the document structure, style and content. - **MDN**" title="" mdxType="Message" />
    <h2>{`Shadow DOM`}</h2>
    <p>{`W3C is working on a draft standard called shadow DOM. It provides much-needed encapsulation for web components, of its CSS, JavaScript and templating.`}</p>
    <Message type="info" title="" content="A document tree is a node tree [ DOM ] whose root node is a document. Any element can host zero or one associated node trees, called a shadow tree . A shadow host is an element that hosts one shadow tree. A shadow tree has an associated flag, called an encapsulation mode , which is either open or closed. - **MDN**" mdxType="Message" />
    <p>{`One of the issues shadow DOM is trying resolve is separating itself from the main content it resides, by stopping CSS leaking into main content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<html>
  <head></head>
  <body>
    <p id="host">
    <script>
      var shadow = document.querySelector('#host').createShadowRoot();
      shadow.innerHTML = 'Hello Shadow DOM!';
      shadow.innerHTML += '<style>p { color: green; }</style>';
    </script>
  </body>
</html>
`}</code></pre>
    <p>{`In this example we are attaching the shadow DOM using `}<b>{`createShadowRoot()`}</b>{` to the `}<b>{`paragraph`}</b>{` element. And then we assign some styles to it.`}</p>
    <Message type="warn" title="" content="The shadow DOM should always be attached to an existing element." mdxType="Message" />
    <p>{`The shadow DOM will only start to be displayed when we assign the paragraph element with content.`}</p>
    <h2>{`Virtual DOM`}</h2>
    <p>{`There is no standard for virtual DOM. But it does the rendering more efficiently by monitoring the changes, making the calculations on the memory and then the final result will be applied to the DOM.`}</p>
    <p>{`The monitoring of data changes related to DOM is monitored in one of two ways: `}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Dirty checking`}</inlineCode>{` - Detect changes to the data by continuously polling at regular intervals`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Observable`}</inlineCode>{` - A state change will trigger an update for only affected nodes as described below.`}</li>
    </ul>
    <p>{`The mechanism for updating DOM uses the following methods:`}</p>
    <ul>
      <li parentName="ul">{`Batch DOM read/writes`}</li>
      <li parentName="ul">{`Fast diff algorithms`}</li>
      <li parentName="ul">{`Sub-tree only updates`}</li>
    </ul>
    <p>{`The methods described above are complex to implement but one of the most popular libraries doing this is `}<inlineCode parentName="p">{`React`}</inlineCode>{`.`}</p>
    <p>{`By batching DOM updates using functional programming, by diffing before and after chunks of framework based custom components, and only updating changes when necessary, virtual DOM increases the maintainability as well as performance.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Virtual DOM enables us to improve the performance of the front-end libraries by significantly reducing the DOM manipulation overhead, by using algorithms with JavaScript objects to mimick the DOM and then rendering the end result to the DOM.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      